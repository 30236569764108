/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background: #ddd;

    .item-close {
        font-size: 24px;
        cursor: pointer;
        background: transparent;
        border: none;
    }

    p {
        margin: 0;
    }

    .links-white {
        margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}

.more-btn {
    position: relative;
    text-align: center;
    span {
        position: relative;
        cursor: pointer;
        padding: 10px 20px 10px 10px;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        display: inline-block;
        &:after {
            position: absolute;
            content: '';
            bottom: 15px;
            right: -4px;
            width: 10px;
            height: 8px;
            background: url('../img/icon/arrow-right.png');
            transform: rotate(90deg);
        }
    }
}

.wpcf7-not-valid-tip {
    font-weight: 100;
    font-size: 12px !important;
    font-style: italic;
}

#back-to-top {
    display: inline-block;
    border: 1px solid #666;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 60px;
    right: 60px;
    transition: background-color .3s,
    opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    cursor: pointer;
    &:after {
        border-bottom: 1px solid #666;
        border-right: 1px solid #666;
        content: '';
        display: block;
        height: 10px;
        margin-top: -6px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        width: 10px;
        transform-origin: 66% 66%;
        transform: rotate(-135deg);
    }
    &:hover, &:active {
        background-color: #666;
        &:after {
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    @include media-breakpoint-down(md) {
        bottom: 20px;
        right: 20px;
    }
}

#pagination {
    display: none;
}

.posts-wrap {
    position: relative;
}

.posts {
    margin-top: -30px;
}
.post {
    margin-top: 30px;
}

.trigger {
    text-align: center;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 100%) !important;
}

.spinner {
    text-align: center;
    position: relative;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
}

.icon-svg {
    display: inline-block;
    svg {
        fill: #333;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
    }
    &:hover {
        svg {
            fill: #999;
        }
    }
}

.tab-pane {
    padding: 20px;
}