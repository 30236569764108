.footer-cta {
    margin: 100px 0 50px;
    &-link {
        display: block;
        background: #f3f3f3;
        padding: 30px;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        transition:800ms ease all;
        outline:none;
        color: #999;
        &:hover {
            background: #D2D2D2;
            color: #fff !important;
        }
    }
}

.footer {
    text-align: center;
    background: #f3f3f3;
    padding: 20px 0;
    &-nav {
        display: flex;
        justify-content: center;
        margin: 0 0 15px;
        .separator {
            position: relative;
            padding: 0 15px;
            line-height: 40px;
            &:before {
                position: absolute;
                content: '•';
                color: #ccc;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        a {
            color: #000;
            font-family: 'Quicksand';
            text-transform: uppercase;
            line-height: 40px;
            font-size: 14px;
        }
    }
    &-newsletter {
        background: #ccc;
        padding: 30px 60px;
        margin: 0 0 15px;
        p {
            margin: 0;
        }
        &-title {
            color: #fff;
            font-family: 'Quicksand';
            text-transform: uppercase;
            font-size: 14px;
            margin: 0 0 15px;
        }
        &-button {
            position: relative;
            input {
                border-radius: 0;
                padding: 10px 10px 10px 50px;
                height: auto;
                position: relative;
                @include placeholder {
                    color: #ccc;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .your-email, .your-firstname, .your-name {
                position: relative;
                display: block;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: url('../img/icon/mail.svg') no-repeat center center;
                    color: #ccc;
                    fill: #ccc;
                    z-index: 2;
                    font-size: 18px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 40px;
                    width: 1px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #ccc;
                    z-index: 2;
                }
            }
            .your-firstname:before, .your-name:before {
                background: url('../img/icon/people.svg') no-repeat center center;
            }
            &-list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                li:first-child {
                    margin-right: 10px;
                }
            }
            &-submit {
                text-align: right;
                position: relative;
            }
            &-send {
                color: #fff;
                background: transparent;
                border: none;
                text-transform: uppercase;
                font-weight: 100;
                padding-top: 15px;
                padding-right: 0;
                transition:800ms ease all;
                font-size: 14px;
                &:focus {
                    outline: none;
                }
                svg {
                    transition:800ms ease all;
                    fill: #fff;
                }
                &:hover {
                    color: #666;
                    svg {
                        fill: #666;
                    }
                }
            }
        }
        .ajax-loader {
            position: absolute;
            top: 22px;
            right: -20px;
        }
        .wpcf7-not-valid-tip {
            margin-top: 10px;
        }
    }
    &-social {
        margin: 0 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            fill: #999;
        }
        a {
            padding: 5px;
            margin: 0 5px;
            &:hover {
                svg {
                    fill: #000;
                }
            }
        }
    }
    &-partner {
        position: relative;
        padding: 15px 0;
        margin: 0 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #999;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #999;
        }
    }
    &-copyright {
        font-family: 'Quicksand';
        font-size: 10px;
        text-transform: uppercase;
        color: #999;
        p {
            margin: 0;
        }
    }
}