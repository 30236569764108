html, body {
    font-family: 'Lato';
    font-weight: 100;
    font-size: 16px;
    color: #000;
    min-width: 320px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

button, button:focus, button:active, button:visited, input, input:focus, input:active, input:visited  {
    outline: none;
}

a{
    position: relative;
    display: inline-block;
    transition: all 0.25s;
    color: #000;
    &:active, &:focus, &:hover, &.active {
        outline: none;
        color: #999 !important;
        text-decoration: none;
        svg {
            fill: #999;
        }
    }
}

a.img-hover-link{
    overflow: hidden;
    img {
        transition: all 0.5s;
    }
    &:hover {
        img {
            transform: scale(1.1) !important;
        }
    }
}

a.no-hover {
    &:after {
        display: none;
    }
}

.link-more {
    position: relative;
    margin-top: 20px;
    padding: 10px 0 10px 0;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    svg {
        margin-left: 10px;
    }
    &:hover {
        color: #999 !important;
        svg {
            fill: #999;
        }
    }
}