.block-destination {
    margin: 0 0 100px;
    &-images {
        position: relative;
        padding: 45px 20px 20px 0;
        margin-top: -45px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(100% 0, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
            z-index: -1;
        }
        ul {
            margin-top: -10px;
            li {
                margin: 10px 0 0 20px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &-link {
            position: relative;
            &-title {
                position: absolute;
                bottom: 15px;
                left: 0;
                width : 100%;
                color: #fff;
                font-family: 'Vidaloka';
                font-weight: 400;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}

.block-carousel-background-item-title-line2 {
    position: relative;
    text-indent: -9999px;
}

.slashed {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    transform: rotate(-37deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.top, .bot {
    text-align: center;
    overflow: hidden;
    color: white;
    display: block;
    font-family: 'Playfair Display';
    font-weight: 700;
    font-size: 70px;
    text-transform: uppercase;
    line-height: normal;
    height: 1000px;
}

.top:before, .bot:before {
    content: attr(title);
    transform: rotate(+37deg);
}

.top {
    position: absolute;
    left:0;
    right:0;
    bottom: 50%;
}

.top:before {
    position: absolute;
    left:0;
    right:0;
    bottom: -44px;
}

.bot {
    position: absolute;
    top:50%;
    left:0;
    right:0;
    color: #000;
}

.bot:before {
    position: absolute;
    top:-50px;
    left:0;
    right:0;
}