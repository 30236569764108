/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
*/

@font-face {
    font-family: 'made-canvas';
    src:
            url('../fonts/made-canvas/made-canvas.eot?#iefix') format('embedded-opentype'),
            url('../fonts/made-canvas/made-canvas.otf') format('opentype'),
            url('../fonts/made-canvas/made-canvas.woff') format('woff'),
            url('../fonts/made-canvas/made-canvas.ttf') format('truetype'),
            url('../fonts/made-canvas/made-canvas.svg#made-canvas') format('svg');
    font-weight: 400;
    font-style: normal;
}