.block-carousel-background {
    position: relative;
    margin: 0 0 150px;
    z-index: 3;
    opacity: 0;
    &-item {
        position: relative;
        a {
            display: block;
            overflow: hidden;
        }
        &-title {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
            text-align: center;
            color: #fff;
            h1 {
                margin: 0;
            }
            &-line1 {
                display: block;
                font-family: 'Quicksand';
                font-weight: 100;
                font-size: 24px;
                text-transform: uppercase;
                line-height: normal;
                margin: 0 0 20px;
            }
            &-line2 {
                display: block;
                font-family: 'Playfair Display';
                font-weight: 700;
                font-size: 70px;
                text-transform: uppercase;
                line-height: normal;
            }
            &-subtitle {
                display: block;
                position: absolute;
                bottom: -100px;
                left: 0;
                width: 100%;
                text-align: center;
                font-family: 'Playfair Display';
                font-weight: 700;
                text-transform: uppercase;
                font-size: 20px;
                line-height: normal;
            }
        }
    }
    &-image {
        &-mobile {
            display: none !important;
        }
    }
    .owl-item {
        overflow: hidden;
    }
    .owl-dots {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, 70px);
        span {
            background: rgba(255, 255, 255, 0.25) !important;
            width: 9px !important;
            height: 9px !important;
        }
        .active {
            span {
                background: rgba(255, 255, 255, 1) !important;
            }
        }
    }
    .search-destination {
        position: absolute;
        padding: 30px 45px;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        background: rgba(255, 255, 255, 0.15);
        z-index: 5;
        display: flex;
        align-items: center;
        font-size: 14px;
        &-or {
            padding: 0 40px;
            text-transform: uppercase;
        }
        .nice-select {
            border: 0;
            border-radius: 0;
            background: rgba(255, 255, 255, 0.35);
            height: auto;
            width: 285px;
            padding: 0 0 0 20px;
            font-weight: 100;
            float: none;
            &:hover  {
                background: rgba(255, 255, 255, 0.6);
            }
            .current {
                line-height: 60px;
                text-transform: uppercase;
            }
            &:after {
                border-color: #000;
                width: 12px;
                height: 12px;
                margin-top: -3px;
                border-bottom-width: 1px;
                border-right-width: 1px;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%) rotate(45deg);
            }
            &.open {
                &:after {
                    transform: translate(0, -50%) rotate(225deg);
                }
            }
            .list {
                width: 100%;
                max-height: 400px;
                overflow: auto;
                border-radius: 0;
                padding: 20px 0;
                .label {
                    font-weight: 400;
                    position: relative;
                    padding: 12px 0 0 10px;
                    display: block;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 20px;
                        width: 5px;
                        height: 1px;
                        background:#000;
                    }
                }
                .option {
                    font-weight: 100;
                    padding: 2px 0 2px 20px;
                }
                .disabled {
                    display: none;
                }
                #optgroup-0 .label {
                    padding: 0 0 0 10px;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.block-title {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 90px;
    &:after {
        content: '';
        width: 60px;
        height: 2px;
        background: #999;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    &-number {
        display: block;
        font-family: 'made-canvas';
        font-size: 250px;
        line-height: normal;
        color: #f3f3f3;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
    }
    &-line-1 {
        display: block;
        font-size: 16px;
        line-height: normal;
        font-weight: 100;
        color: #666;
    }
    &-line-2 {
        display: block;
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 36px;
    }
    &.block-title-right {
        text-align: right;
        &:after {
            left: auto;
            right: 0;
            transform: none;
        }
        .block-title-number {
            left: auto;
            right: -6px;
            transform: translate(0, -45%);
        }
    }
    &.block-title-left {
        text-align: left;
        &:after {
            left: 0;
            transform: none;
        }
        .block-title-number {
            left: -6px;
            transform: translate(0, -45%);
        }
    }
}

.block-title-2 {
    font-family: "Playfair Display";
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    margin: 0 0 20px;
}

.description {
    h2 {
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 24px;
        margin: 0 0 20px;
    }
    h3 {
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 20px;
    }
    h4 {
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 20px;
    }
    ul {
        margin: 20px 0;
        li {
            padding: 0 0 5px 20px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                background: #000;
                width: 4px;
                height: 4px;
                border-radius: 50%;
            }
        }
    }
    strong {
        font-weight: 400;
    }
    p {
        margin: 0 0 5px;
    }
}

.block-carousel-experience {
    text-align: center;
    padding: 40px 30px 0;
    margin: 0 0 150px;
    position: relative;
    &-item {
        &-category {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
            margin: 0 0 10px;
        }
        &-title {
            display: block;
            font-family: 'Playfair Display';
            font-weight: 700;
            display: block;
            font-size: 20px;
            margin: 0 0 10px;
        }
        &-description {
            display: block;
            margin: 0 0 30px;
        }
        &-link {
            width: 100%;
            height: 190px;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            -moz-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            background: #fff;
            img {
                width: auto !important;
            }
        }
        &-image {

        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height : 100%;
        width : 100%;
        background: #f3f3f3;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
    }
    .owl-item {
    }
    .owl-theme .owl-nav {
        margin: 0;
        .owl-next, .owl-prev {
            margin: 0;
        }
        .nav-btn {
            padding: 15px 10px;
            &:hover {
                svg {
                    color: #999;
                }
            }
        }
        & [class*='owl-']:hover {
            background: transparent;
            color: #000;
        }
    }
    &-more {
        position: absolute;
        bottom: 53px;
        right: 25px;
        transform: rotate(90deg);
        padding: 0 0 5px 0;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        svg {
            margin-left: 10px;
        }
    }
}

.page-template-partner .block-carousel-experience .block-inspiration-head-category.owl-item {padding: 0 10px 10px;}

.block-inspiration {
    text-align: center;
    margin: 0 0 150px;
    .container {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(360px 0, 100% 700px, 360px 700px);
            -webkit-clip-path: polygon(360px 0, 100% 700px, 360px 700px);
        }
    }
    .block-title {
        margin-top: 55px;
        margin-bottom: 90px;
    }
    &-desc {
        text-align: left;
    }
    &-category {
        display: block;
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #666;
        margin: 0 0 10px;
    }
    &-title {
        display: block;
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 10px;
        margin: 0 0 10px;
    }
    &-description {
        display: block;
        padding: 0 10px;
        margin: 0 0 15px;
    }
    &-1 {
        padding: 180px 0 0;
        .block-inspiration-category {
            margin-top: 15px;
        }
    }
    &-2 {
        margin: 0 0 30px;
        &.without-text {
            padding: 100px 0 0;
        }
    }
    &-3 {
        max-width: 400px;
        padding-right: 30px;
        position: relative;
        .block-inspiration-image {
            position: relative;
            margin: 0 0 15px;
        }
        .block-inspiration-description {
            margin-bottom: 0;
        }
    }
    .block-inspiration-more {
        position: absolute;
        bottom: 2px;
        right: -10px;
        transform: rotate(90deg);
        padding: 0 0 5px 0;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        svg {
            margin-left: 10px;
        }
    }
}
.page-template-about_us .block-inspiration {
    margin-bottom: 100px;
}

.block-philosophy {
    text-align: center;
    &-items {
        margin-top: -30px;
    }
    &-item {
        position: relative;
        padding-top: 25px;
        margin-top: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(50% 0, calc(100% - 35px) 160px, 35px 160px);
            -webkit-clip-path: polygon(50% 0, calc(100% - 35px) 160px, 35px 160px);
            z-index: -1;
        }
        &-title {
            font-family: 'Playfair Display';
            font-size: 20px;
            font-weight: 700;
            margin: 0 0 10px;
        }
        &-link {
            width: 100%;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            -moz-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.3);
            background: #fff;
            img {
                width: auto !important;
            }
        }
        &-description {
            padding: 0 10px;
            margin: 0 0 30px;
        }
    }
    &-more {
        text-align: right;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        position: relative;
        margin: 20px 0 0;
        padding: 0 20px 0 0;
        &:after {
            position: absolute;
            content: '';
            top: 3px;
            right: 0;
            width: 10px;
            height: 8px;
            background: url('../img/icon/arrow-right.png');
        }
    }
}
.page-template-about_us .block-philosophy {
    margin-bottom: 150px;
}
.page-template-partner .block-philosophy-item {
    margin-top: 30px;
}

.breadcrumbs {
    padding: 30px 0;
    font-family: 'Quicksand';
    font-weight: 400;
    text-transform: uppercase;
    font-size: 11px;
    .separator {
        padding: 0 10px;
        position: relative;
        color: #999;
    }
    a {
        color: #999;
        &:hover {
            color: #000 !important;
        }
    }
}

.breadcrumbs-only {
    margin: 0 0 70px;
}

.breadcrumbs-begin {
    padding: 142px 0 0 !important;
    margin: 0 0 70px;
}

.block-content.breadcrumbs-begin {
    margin-top: 0;
}

.page-template-partner .breadcrumbs-only, .page-template-question_answer .breadcrumbs-only, .search .breadcrumbs-only{
    margin-bottom: 120px;
}

.block-gallery {
    &-items {
        margin-top: -30px;
    }
    &-item {
        margin-top: 30px;
        position: relative;
        text-align: center;
        &-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            &-line1 {
                display: block;
                font-size: 12px;
                text-transform: uppercase;
                color: #fff;
                margin: 0 0 5px;
            }
            &-line2 {
                display: block;
                font-family: 'Playfair Display';
                font-weight: 700;
                font-size: 24px;
                position: relative;
                color: #fff;
                padding: 0 0 10px;
                &:after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 50%;
                    width: 50px;
                    height: 1px;
                    background: #fff;
                    transform: translate(-50%, 0);
                }
            }
        }
    }
}

.block-content {
    max-width: 700px;
    margin: 0 auto;
    background: #fff;
    margin-top: -250px;
    margin-bottom: 155px;
    position: relative;
    z-index: 4;
    text-align: center;
    padding: 0 50px;
    &.with-line:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100px;
        height: 1px;
        background: #999;
    }
    &-title {
        display: block;
        color: #666;
        font-weight: 100;
        font-size: 16px;
        text-transform: uppercase;
    }
    &-subtitle {
        display: block;
        font-family: "Playfair Display";
        font-weight: 700;
        font-size: 36px;
        margin: 0 0 20px;
    }
    &-image {
        margin: 0 0 20px;
    }
    &-quote {
        max-width: 400px;
        font-family: "Playfair Display";
        font-weight: 700;
        margin: 0 auto 20px;
    }
    &-description {
        text-align: left;
        margin: 0 0 20px;
        padding: 0 0 20px;
    }
    &-bullets {
        &-line {
            ul {
                display: flex;
                li {
                    flex: 1 1 0px;
                    padding: 0 20px;
                    &.center {
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: #f3f3f3;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 100%;
                            top: 0;
                            right: 0;
                            background: #f3f3f3;
                        }
                    }
                }
            }
            &-picto {
                color: #999;
                font-size: 17px;
            }
            &-title {
                color: #999;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                margin: 5px 0;
            }
            &-description {
                font-size: 14px;
            }
            &.first {
                padding: 0 0 20px;
                margin: 0 0 20px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background: #f3f3f3;
                }
            }
        }
    }
}
.single-pays .block-content {
    margin-bottom: 120px;
}

.block-carousel-inspiration {
    position: relative;
    padding: 50px 0 30px;
    margin: 0 0 150px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height : 100%;
        width : 100%;
        background: #f3f3f3;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
    }
    &-wrap {
        max-width: 900px;
        margin: 0 auto;
    }
    &-item {
        &-wrap {
            background: #fff;
            height: 100%;
            padding: 30px;
            margin: 30px 0 0;
            height: 500px;
            display: flex;
            align-items: center;
        }
        &-title {
            display: block;
            color: #666;
            font-weight: 100;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        &-subtitle {
            display: block;
            font-family: "Playfair Display";
            font-weight: 700;
            font-size: 20px;
            margin: 0 0 10px;
        }
        &-description {
        }
    }
    .owl-theme .owl-nav {
        position: absolute;
        bottom: -5px;
        right: 0;
        .owl-next {
            margin: 0;
        }
        .nav-btn {
        }
        & [class*='owl-']:hover {
            background: transparent;
            color: #000;
        }
    }
}

.block-map {
    margin: 0 0 100px;
    .block-title {
        margin-top: 50px;
    }
    .section-map{
        padding: 0 30px 30px 0;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(100% 0, 100% 100%, 100px 100%);
            -webkit-clip-path: polygon(100% 0, 100% 100%, 100px 100%);
        }
        #map {
            position: relative;
            width: 100%;
            height: 50vh;
        }
        &.section-map-left {
            padding: 0 0 30px 30px;
            &:before {
                clip-path: polygon(0 10%, 70% 100%, 0 100%);
                -webkit-clip-path: polygon(0 10%, 70% 100%, 0 100%);
            }
        }
    }
}

.single-pays .block-map {
    margin-bottom: 150px;
}

.block-accomodation {
    &-filter {
        position: relative;
        z-index: 2;
        &-btn {
            text-transform: uppercase;
            text-align: right;
            font-size: 16px;
            margin: 0 0 20px;
            cursor: pointer;
            font-weight: 400;
            &:hover, &.active {
                color: #999;
            }
            &.active + .block-accomodation-filter-box {
                opacity: 1;
                display: block;
                z-index: 2;
            }
        }
        &-box {
            opacity: 0;
            display: none;
            position: absolute;
            top: calc(100% + 20px);
            right: 0;
            background: #fff;
            padding: 30px 30px 30px 30px;
            z-index: -1;
            text-align: right;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            -webkit-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
            -moz-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
            box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
            &-title {
                font-weight: 400;
                font-size: 14px;
                margin: 0 0 20px;
            }

            &-col:first-child {
                margin-right: 30px;
            }

            .checkbox-label {
                margin: 0;
                clear: both;
                font-size: 14px;
                color: #000;
                display: block;
                text-align: right;
            }

            .checkbox-container {
                text-align: right;
                margin: 0 0 5px;
                position: relative;
                padding-right: 20px;
            }

            .checkbox-container-input {
                position: absolute;
                top: 4px;
                right: 0;
                display: block;
                cursor: pointer;
                font-size: 22px;
                line-height: 10px;
                height: 10px;
                width: 10px;
                clear: both;
            }

            .checkbox-container-input input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                top: 0;
                left: 0;
                z-index: 2;
            }

            .checkbox-container-input .checkbox-custom {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 10px;
                width: 10px;
                background-color: transparent;
                transition: all 0.3s ease-out;
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -ms-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
                border: 1px solid #000;
            }

            .checkbox-container-input input:checked ~ .checkbox-custom {
                border-radius: 0;
                -webkit-transform: rotate(0deg) scale(1);
                -ms-transform: rotate(0deg) scale(1);
                transform: rotate(0deg) scale(1);
                opacity:1;
                border: 1px solid #000;
            }

            .checkbox-container-input .checkbox-custom::after {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                height: 10px;
                width: 10px;
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
                opacity:1;
                transition: all 0.3s ease-out;
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -ms-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
            }

            .checkbox-container-input input:checked ~ .checkbox-custom::after {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
                opacity:1;
                left: 1px;
                top: 1px;
                width: 6px;
                height: 6px;
                background-color: #000;
            }
            &-submit {
                display: inline-block;
                margin-top: 20px;
                padding: 10px 30px;
                border: 1px solid #000;
                text-transform: uppercase;
                font-size: 12px;
                transition: all 0.3s ease-out;
                -webkit-transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -ms-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
                cursor: pointer;
                &:hover {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
    &-items {
    }
    &-item {
        text-align: center;
        padding-bottom: 30px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(0 50%, 100% 50%, 50% 100%);
            -webkit-clip-path: polygon(0 50%, 100% 50%, 50% 100%);
            z-index: -1;
        }
        &-image {
            margin: 0 0 10px;
        }
        &-title {
            font-family: 'Playfair Display';
            font-weight: 700;
            font-size: 20px;
            margin: 0 0 10px;
        }
        &-subtitle {
            text-transform: uppercase;
            font-size: 12px;
            color: #666;
        }
    }
}

.block-hotel-content {
    margin: 0 0 100px;
    &-title {
        margin: 0 0 50px;
        text-align: center;
        .title {
            font-family: "Playfair Display";
            font-size: 36px;
            font-weight: 700;
            margin: 0 0 10px;
            text-transform: uppercase;
        }
        .subtitle {
            font-size: 16px;
            font-weight: 100;
            color: #666;
            text-transform: uppercase;
        }
    }
    &-description {
        &-center {
            position: relative;
            padding: 0 30px;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width : 1px;
                height : 100%;
                background: #ccc;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width : 1px;
                height : 100%;
                background: #ccc;
            }
        }
    }
}

.block-hotel-gm {
    margin: 0 0 100px;
    &-filter {
        margin: 0 0 10px;
        ul {
            display: flex;
            justify-content: center;
        }
        &-gallery {
            cursor: pointer;
            &:hover, &.active {
                font-weight: 400;
            }
        }
        &-separator {
            padding: 0 10px;
        }
        &-map {
            cursor: pointer;
            &:hover, &.active {
                font-weight: 400;
            }
        }
    }
    &-map {
        display: none;
        .section-map{
            padding: 0;
            position: relative;
            #map {
                position: relative;
                width: 100%;
                height: 545px;
            }
        }
    }
}

.block-inspiration-head {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 15px;
        background: #ccc;
        width: calc(100% - 30px);
        height: 1px;
    }
    &-category {
        text-transform: uppercase;
        color: #666;
        font-size: 14px;
        margin: 0 0 10px;
    }
    &-title {
        font-family: "Playfair Display";
        font-size: 36px;
        font-weight: 700;
        padding: 0 0 20px;
        margin: 0 0 10px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            background: #ccc;
            width: 50px;
            height: 2px;
        }
    }
    &-subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #999;
        margin: 0 0 10px;
        text-transform: uppercase;
    }
    &-socials {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px;
        a {
            border: 1px solid #999;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
    }
    &-description {
        max-width: 450px;
        margin: 0 auto;
        font-weight: 400;
    }
}

.block-inspiration-content {
    &-text {
        a {
            text-decoration: underline;
            text-decoration-color: orange;
        }
    }
    &-title {
        text-align: center;
    }
    &-image {
        position: relative;
        margin: 0 0 15px;
        &-legend {
            position: absolute;
            top: 50%;
            left: calc(100% + 15px);
            transform: translate(0 , -50%);
            width: 120px;
            padding: 10px 0 0;
            font-size: 14px;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: #ccc;
                width: 40px;
                height: 2px;
            }
            &-copyright {
                color: #999;
                margin-top: 10px;
            }
        }
    }
    &-quote {
        font-family: "Playfair Display";
        font-weight: 700;
        font-style: italic;
        position: relative;
        padding: 30px 0;
        margin: 0 auto 15px;
        max-width: 400px;
        text-align: center;
        font-size: 18px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            background: #ccc;
            width: 40px;
            height: 2px;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            background: #ccc;
            width: 40px;
            height: 2px;
        }
    }
    &-share {
        padding: 30px 0 0;
        &-title {
            text-transform: uppercase;
            text-align: center;
            margin: 0 0 10px;
            font-weight: 400;
            font-size: 14px;
        }
        &-socials {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            a {
                border: 1px solid #999;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                background: #ccc;
                width: calc(50% - 50px);
                height: 1px;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 0;
                background: #ccc;
                width: calc(50% - 50px);
                height: 1px;
            }
        }
    }
    &-other {
        margin-top: 100px;
    }
}

.block-history {
    margin-bottom: 100px;
    &-wrap {
        padding: 0 30px 30px 30px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(100% 20%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(100% 20%, 100% 100%, 0 100%);
            z-index: -1;
        }
    }
    .block-title {
        transform: translate(0, 50px);
    }
    &-description {
        margin: 0 0 20px;
    }
}

.block-philosophy {
    margin: 0 0 100px;
    &-title {
        font-family: 'Playfair Display';
        font-weight: 700;
        font-size: 20px;
        margin: 20px 0 10px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -80px;
            left: 0;
            height : 160px;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(0 0, 100% 0, 50% 160px);
            -webkit-clip-path: polygon(0 0, 100% 0, 50% 160px);
            z-index: -1;
        }
    }
    &-quote {
        font-style: italic;
        margin: 0 0 20px;
        font-size: 14px;
    }
    .block-title {
        transform: translate(0, 50px);
    }
}

.block-concept {
    &-title {
        font-family: "Playfair Display";
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 10px;
    }
    &-images {
        display: flex;
        justify-content: center;
        padding: 0 20px 20px 20px;
        position: relative;
        > div {
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(100% 10%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(100% 10%, 100% 100%, 0 100%);
            z-index: -1;
        }
        &.inverse:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height : 100%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(0 10%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 10%, 100% 100%, 0 100%);
            z-index: -1;
        }
    }
}

.block-qr {
    &-wrap {
        margin-bottom: 150px;
    }
    &-question {
        padding: 25px 30px;
        background: #f3f3f3;
        text-transform: uppercase;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        p {
            margin: 0;
        }
        &:after {
            font-family: "Font Awesome 5 Pro";
            content: '\f067';
            position: absolute;
            top: 26px;
            right: 20px;
            color: #000;
        }
        &.active:after {
            content: '\f068';
        }
        &-wrap {
            margin: 0 0 4px;
        }
    }
    &-answer {
        padding: 25px 30px;
        display: none;
        p {
            margin: 0;
        }
    }
}

.block-selected-press {
    margin-bottom: 100px;
    .block-title {
        transform: translate(0, 50px);
    }
    &-images {
        padding: 0 60px 30px;
        text-align: center;
        position: relative;
        &-block {
            position: relative;
            padding-top: 100px;
            a.img-hover-link {
                -webkit-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
                -moz-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
                box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height : 100%;
                width : 100%;
                background: #f3f3f3;
                clip-path: polygon(0 0, 100% 65%, 0 65%);
                -webkit-clip-path: polygon(0 0, 100% 65%, 0 65%);
                z-index: -1;
            }
        }
    }
    &-block {
        margin-left: 30px;
        width: 210px;
        &:first-child {
            margin-left: 0;
        }
    }
    &-category {
        text-transform: uppercase;
        font-size: 12px;
        margin: 15px 0 10px;
        color: #666;
    }
    &-title {
        font-family: "Playfair Display";
        font-weight: 700;
        font-size: 20px;
    }
    &-image {
        position: relative;
    }
    &-more {
        position: absolute;
        margin-top: 0;
        bottom: 0;
        right: -8px;
        transform: rotate(90deg);
        padding: 10px 0 10px 0;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
        text-transform: uppercase;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        svg {
            margin-left: 10px;
        }
    }
}

.block-press {
    margin-bottom: 150px;
    .block-selected-press-category {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -80px;
            left: 0;
            height : 160px;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(0 0, 100% 0, 50% 160px);
            -webkit-clip-path: polygon(0 0, 100% 0, 50% 160px);
            z-index: -1;
        }
    }
    .link-more {
        margin-top: 0;
    }
}

.more-link {
    text-align: center;
    margin-top: 50px;
}

.block-press-articles {
    &-item {
        margin-bottom: 30px;
        display: inline-block;
        display: flex;
        position: relative;
        &-image {
            flex-shrink: 0;
            position: relative;
            padding: 15px 30px 15px 0;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height : 100%;
                width : 100%;
                background: #f3f3f3;
                clip-path: polygon(20px 0, 100% 100%, 20px 100%);
                -webkit-clip-path: polygon(20px 0, 100% 100%, 20px 100%);
                z-index: -1;
            }
        }
        &-content {
            padding-top: 30px;
            padding-bottom: 30px;
            &-category {
                font-size: 12px;
                color: #666;
                text-transform: uppercase;
                margin: 0 0 5px;
            }
            &-title {
                font-family: "Playfair Display";
                font-weight: 700;
                font-size: 20px;
                margin: 0 0 10px;
            }
            &-description {
                a {
                    strong {
                        font-weight: 400;
                    }
                }
            }
        }
        .more-link {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 0;
            .link-more {
                margin-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

.block-join {
    margin-bottom: 100px;
    .block-title {
        transform: translate(0, 50px);
    }
    &-description {
        padding: 0 0 0 30px;
        line-height: 1.5;
        &-wrap {
            margin: 0 0 20px;
        }
        &-letter {
            color: #999;
            display: inline-block;
            margin-right: 5px;
        }
        .middle {
            margin: 0 0 20px;
            padding: 20px 0;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height : 1px;
                width : 50px;
                background: #ccc;
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height : 1px;
                width : 50px;
                background: #ccc;
            }
        }
    }
    &-images {
        padding: 0 30px 30px 30px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -20%;
            left: 0;
            height : 120%;
            width : 100%;
            background: #f3f3f3;
            clip-path: polygon(100% 0%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(100% 0%, 100% 100%, 0 100%);
            z-index: -1;
        }
    }
}

.block-contact-form {
    font-size: 16px;
    .block-title {
        transform: translate(0, 50px);
    }
    &-wrap {
        color: #999;
        padding: 60px;
        -webkit-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
        -moz-box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
        box-shadow: 0px 0px 13px -1px rgba(82,82,82,0.2);
    }
    &-step {
        font-family: "Playfair Display";
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 20px;
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                &.separator {
                    padding: 0 10px;
                }
                &.active {
                    color: #000;
                }
            }
        }
    }
    .nice-select {
        border: 0;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.35);
        width: 100%;
        height: auto;
        padding: 0;
        font-weight: 100;
        border-bottom: 1px solid #ccc;
        margin: 0 0 10px;
        font-size: 16px;
        float: none;
        .current {
            line-height: 30px;
        }
        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #ccc transparent transparent transparent;
            top: 20px;
            right: 5px;
            transform: rotate(0deg);
        }
        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
        .list {
            width: 100%;
            max-height: 400px;
            overflow: auto;
            border-radius: 0;
            padding: 20px 0;
            .label {
                font-weight: 400;
                position: relative;
                padding: 12px 0 0 10px;
                display: block;
                color: #000;
                &:before {
                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 20px;
                    width: 5px;
                    height: 1px;
                    background:#000;
                }
            }
            .option {
                font-weight: 100;
                padding: 2px 0 2px 20px;
                color: #000;
            }
            .disabled {
                display: none;
            }
            #optgroup-0 .label {
                padding: 0 0 0 10px;
                &:before {
                    display: none;
                }
            }
        }
    }
    input {
        width: 40px;
        border: none;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
    }
    .mandatory-fields {
        text-align: right;
        font-size: 12px;
        font-style: italic;
        margin-top: 20px;
        span {
            display: inline-block;
            position: relative;
            &:before {
                content: '*';
                position: absolute;
                top: -6px;
                left: -8px;
                width: auto;
                height: auto;
                color: red;
                font-size: 16px;
            }
        }
    }
    .wpcf7 {
        .multistep-nav {
            margin: 30px 0 0;
        }
        p {
            margin: 0;
        }
        label {
            padding-right: 5px;
        }
    }
    .multistep-cf7-next {
        background: #f3f3f3 !important;
        color: #999 !important;
        padding: 10px 50px;
        text-transform: uppercase;
        font-weight: 100;
        border: none;
        display: inline-block;
        width: auto !important;
        transition:800ms ease all;
        outline:none;
        &:hover {
            background:#D2D2D2 !important;
            color:#fff !important;
        }
    }
    .more-space {
        margin-bottom: 10px;
    }
    .wpcf7-validates-as-required {
        .current {
            position: relative;
            color: #000;
            &-start {
                color: #999;
            }
            span {
                content: '*';
                position: absolute;
                top: -6px;
                left: calc(100% + 2px);
                width: auto;
                height: auto;
                color: red;
                font-size: 16px;
            }
        }
    }
    label {
        span {
            position: relative;
            color: red;
            left: 2px;
        }
    }

    .cf7-tab-2 {
        .nice-select {
            margin: 0;
        }
        input, textarea {
            width: 100%;
            font-weight: 100;
            &::placeholder {
                color: #999;
                opacity: 1;
                font-weight: 100;
            }
            &::-ms-input-placeholder {
                color: #999;
                font-weight: 100;
            }
            &::-ms-input-placeholder {
                color: #999;
                font-weight: 100;
            }
        }
        textarea {
            padding: 15px;
            height: 150px;
            border: 1px solid #ccc;
        }
        .wpcf7-form-control-wrap {
            display: inline-block;
            margin: 0 0 20px;
            width: 100%;
        }
        .acceptance {
            font-size: 14px;
            label {
                span {
                    position: static;
                }
            }
            .wpcf7-form-control-wrap {
                width: auto;
                margin: 0;
            }
            #newsletter-ch, #cgv-ch {
                margin: 0 0 10px;
            }
        }
        span.wpcf7-list-item {
            margin: 0 20px 0 0;
        }
        .submit-wrap {
            text-align: right;
            margin-top: 30px;
        }
        .multistep-nav {
            display: none;
        }
        .submit-wrap-wrap {
            text-align: right;
        }
    }
    .cf7-tab-2 .wpcf7-not-valid-tip {
        margin: 10px 0 0;
    }
    .wpcf7-not-valid-tip {
        margin-bottom: 20px;
    }
    .submit-wrap-wrap {
        text-align: center;
    }
    .submit-wrap {
        input {
            border: none;
            background: #f3f3f3 !important;
            color: #999 !important;
            text-transform: uppercase;
            font-weight: 100;
            border: none;
            display: inline-block;
            width: auto !important;
            transition:800ms ease all;
            outline:none;
            margin: 0;
            padding: 10px 40px 10px 40px;
        }
        .ajax-loader { display: none;}
        border: none;
        background: #f3f3f3 !important;
        color: #999 !important;
        text-transform: uppercase;
        font-weight: 100;
        border: none;
        display: inline-block;
        width: auto !important;
        transition:800ms ease all;
        outline:none;
        text-align: center;
        position: relative;
        &:hover {
            background:#D2D2D2 !important;
            color:#fff !important;
            input {
                background:#D2D2D2 !important;
                color:#fff !important;
            }
        }
    }
}

.block-devis-form {
    &-title {
        font-family: "Playfair Display";
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin: 0 0 100px;
        color: #000;
        text-transform: uppercase;
    }

    &-othertitle {
        font-family: "Playfair Display";
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin: 0 0 40px;
        color: #000;
    }

    .block-contact-form-wrap1 {
        margin: 0 0 50px;
        position: relative;
        &:before {
            content: '';
            width: 9px;
            height: 9px;
            position: absolute;
            top: -51px;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 50%;
            background: #ccc;
        }
        &:after {
            content: '';
            width: 1px;
            height: 50px;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, 0);
            background: #ccc;
        }
    }

    .block-contact-form-wrap2 {
        position: relative;
        &:before {
            content: '';
            width: 1px;
            height: 50px;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, 0);
            background: #ccc;
        }
    }

    &-description {
        margin: 20px 0;
        font-size: 14px;
        text-align: center;
    }
}

.search {
    position: relative;
    &-nb-posts {
        margin: 0 0 50px;
    }
    &-post {
        background: #f3f3f3;
        padding: 30px;
        margin-bottom: 20px;
        align-items: center;
    }
    &-type {
        text-transform: uppercase;
    }
}

#newsletter-ch, #cgv-ch {
    width: 20px;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    border: none;
    background: #999;
    color: #fff;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
    text-align: center;
}

div.wpcf7-mail-sent-ok {
    border: none;
    background: #319962;
    color: #fff;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
    text-align: center;
}

.filter-icon {
    position: relative;
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('../img/icon/icon_filter.svg');
    top: 4px;
}

