.header {
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 12;
    -webkit-box-shadow: 0px 10px 21px -10px rgba(82,82,82,0.2);
    -moz-box-shadow: 0px 10px 21px -10px rgba(82,82,82,0.2);
    box-shadow: 0px 10px 21px -10px rgba(82,82,82,0.2);
    &-logo {
        text-align: center;
        padding: 20px 0;
        background: rgba(255, 255, 255, 0.8);
        a {
            z-index: 10;
        }
    }
    .wrap-menu {
        background: rgba(255, 255, 255, 0.8);
    }
    nav {
        ul {
            display: flex;
            justify-content: center;
            > li {
                > ul {
                    position: relative;
                    display : none;
                }
                &:hover {
                    > ul {
                        background: rgba(255, 255, 255, 0.6);
                        width: 100%;
                        position: absolute;
                        display: flex;
                        bottom: -40px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }
            }
            li {
                &.separator {
                    position: relative;
                    padding: 0 20px;
                    line-height: 40px;
                    &:before {
                        position: absolute;
                        content: '•';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                ul li.separator {
                    padding: 0 15px;
                }
                a, span {
                    color: #000;
                    font-family: 'Quicksand';
                    text-transform: uppercase;
                    line-height: 40px;
                    font-size: 14px;
                }
                a {
                    transition:800ms ease all;
                    outline:none;
                    &:hover {
                        color:#000 !important;
                    }
                    &:after {
                        content:'';
                        position:absolute;
                        top:0;
                        right:0;
                        height:1px;
                        width:0;
                        background: #000;
                        transition:400ms ease all;
                    }
                    &:after{
                        right:inherit;
                        top:inherit;
                        left:0;
                        bottom:0;
                    }
                    &:hover:after{
                        width:100%;
                        transition:800ms ease all;
                    }
                    &:hover:after{
                        width:100%;
                        transition:800ms ease all;
                        transform: none;
                    }
                }
            }

        }
    }
    .top-bar {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 12px;
        &-contact, &-phone {
            text-transform: uppercase;
            svg {
                margin-right: 5px;
            }
        }
        &-phone {
            svg {
                transform: rotate(-90deg);
            }
        }
        &-separator {
            width: 1px;
            height: 10px;
            background: #ccc;
            margin: 0 15px;
            &.last {
                margin-right: 10px;
            }
        }
        &-social {
            a {
                padding: 5px;
            }
        }
        .icon-svg {
            position: relative;
            top: -2px;
        }
    }

    .wrap-btn-menu {
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 4px 0;
        display: none;
    }

    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        z-index: 10;
        padding: 5px;
        position: relative;
        border: 1px solid transparent;

        .item-burger {
            display: block;
            width: 20px;
            margin: 0 3px;
            position: relative;
            top: 1px;
            &>span {
                display: block;
                width: 100%;
                height: 1px;
                background-color: #000;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 4px 0;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        nav {
            display: none;
        }
        .wrap-btn-menu {
            display: block;
        }
    }
    @include media-breakpoint-down(sm) {
        .logo {
            width: 180px;
        }
    }
}

#mobile-menu{
    position: absolute;
    z-index: 9;
    top: 114px;
    left: 0;
    width: 100vw;
    background-color: $white;
    overflow: auto;
    display:none;
    border-top: 1px solid #eee;
    padding: 30px;

    .item-container{

        ul {
            position: relative;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            &.first-list {
                text-align: left;
            }
            & > li {
                text-transform: uppercase;
                font-family: 'Quicksand';
                &.menu-item-has-children:after {
                }
                a, span {
                    color: #000;
                    font-size: 18px;
                    font-weight: 200;
                    line-height: 45px;
                    -webkit-font-smoothing: antialiased;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    border-bottom: 1px solid #eee;
                }
                span {
                    position: relative;
                    display: inline-block;
                    transition: all 0.25s;
                    color: #000;
                    display: block;
                    border-bottom: 1px solid #eee;
                    &:active, &:focus { outline: none; box-shadow: none !important; color: #000}
                    &:after {
                        transition: all 0.3s ease-out;
                        -webkit-transition: all 0.3s ease-out;
                        -moz-transition: all 0.3s ease-out;
                        -ms-transition: all 0.3s ease-out;
                        -o-transition: all 0.3s ease-out;
                        border-color: #000;
                        width: 12px;
                        height: 12px;
                        margin-top: -3px;
                        top: 50%;
                        right: 15px;
                        transform: translate(0, -50%) rotate(45deg);
                        border-bottom: 1px solid #999;
                        border-right: 1px solid #999;
                        content: '';
                        display: block;
                        pointer-events: none;
                        position: absolute;
                        transform-origin: 66% 66%;
                    }

                    &.active {
                        &:after {
                            transform: translate(0, -50%) rotate(225deg);
                        }
                    }
                    &.from-right:after {
                        transform-origin: bottom right;
                    }
                    &.from-right:hover:after {
                        transform-origin: bottom right;
                    }
                    @include media-breakpoint-down(md) {
                        cursor: pointer !important;
                        span {
                            cursor: pointer !important;
                        }
                    }
                }
                ul {
                    padding: 10px 30px;
                    display: none;
                    white-space: nowrap;
                    transition: all 0.3s ease-out;
                    -webkit-transition: all 0.3s ease-out;
                    -moz-transition: all 0.3s ease-out;
                    -ms-transition: all 0.3s ease-out;
                    -o-transition: all 0.3s ease-out;
                }
                ul li {
                    a {
                        border: none;
                        font-size: 14px;
                        line-height: 28px;
                    }
                }
            }
        }
        .menu-devis, .menu-call {
            margin-top: 30px;
            display: block;
            background: #f3f3f3;
            padding: 30px;
            text-transform: uppercase;
            font-size: 14px;
            text-align: center;
            transition: 800ms ease all;
            outline: none;
            color: #000;
        }
        .menu-call {
            margin-top: 20px;
        }
        .menu-search .search-form {
            justify-content: space-between;
            input {
                font-size: 18px;
                font-weight: 100;
                padding: 10px 0;
                width: 100%;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .item-container ul > li a:after {
            bottom: 5px !important;
        }
    }
    @include media-breakpoint-down(xs) {
        top: 74px;
    }
}

.search-btn-wrap {
    position: absolute !important;
    right: 10px;
    bottom: 0;
    font-size: 13px;
    cursor: pointer;
    padding: 10px;
}

.search-form {
    display: flex;
    justify-content: center;
    align-items: center;
    &-wrap {
        background: rgba(255, 255, 255, 0.4);
        width: 100%;
        display: none;
    }
    &-submit {
        font-size: 20px;
        border: none;
        background: none;
        color: #999;
    }
    &-close {
        font-size: 33px;
        border: none;
        background: none;
        color: #999;
        margin-left: 20px;
    }
    input {
        background: transparent;
        border: none;
        width: auto;
        height: auto;
        line-height: 30px;
        font-size: 14px;
        font-family: "Quicksand";
        color: #000;
        text-transform: uppercase;
        &::placeholder {
            color: #000;
            font-weight: 100;
        }
        &::-ms-input-placeholder {
            color: #000;
            font-weight: 100;
        }
        &::-ms-input-placeholder {
            color: #000;
            font-weight: 100;
        }
        &:focus {
            background: transparent;
        }
    }
}

.header-social {
    margin: 20px 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: #999;
    }
    a {
        padding: 5px;
        margin: 0 5px;
        svg {
            fill: #000;
            width: 25px;
            height: 25px;
        }
        &:hover {
            svg {
                fill: #000;
            }
        }
    }
}