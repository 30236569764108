@include media-breakpoint-down(xl) {
    .block-carousel-background-item-title-line1 { font-size: 18px; }
    .block-carousel-background-item-title-line2, .top, .bot { font-size: 50px; }
    .top:before { bottom: -28px; }
    .bot:before { top: -39px; }
    .block-carousel-background-item-title-subtitle { font-size: 16px; bottom: -50px;}
    .block-carousel-background .owl-dots { margin: 0; top: 44%; }
}

@include media-breakpoint-down(lg) {
    .block-carousel-background-item-title-line1 { font-size: 18px; }
    .block-carousel-background-item-title-line2, .top, .bot { font-size: 35px; }
    .top:before { bottom: -17px; }
    .bot:before { top: -30px; }
    .block-carousel-background .search-destination { background: #f3f3f3; position: static; transform: none; justify-content: center; }
    .block-carousel-background .search-destination-wrap { display: flex; justify-content: center; }
    .block-carousel-background .owl-dots { position: static; transform: none; margin: 0 0 10px; }
    .owl-theme .owl-dots .owl-dot span { background: #f3f3f3 !important; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span { background: #ccc !important; }
    .block-carousel-background-item-title-subtitle { position: static; }
    .footer-cta-link { font-size: 12px; }
    .block-title-number { font-size: 200px; left: 50% !important; right: auto !important; transform: translate(-50%, -45%) !important; }
    .block-title-line-2 { font-size: 25px; }
    section { margin: 0 auto 70px !important; }
    .block-title { margin: 0 0 20px; padding: 50px 0; text-align: center !important; transform: none !important; }
    .block-title:after { left: 50% !important; transform: translate(-50%, 5px) !important; bottom: auto; }
    .block-inspiration .block-title { margin: 0 0 20px; padding: 50px 0; }
    .block-carousel-background { margin: 0 0 70px; }
    .block-carousel-experience { margin: 0 0 70px; }
    .block-carousel-experience-item {  }
    .block-philosophy-item:before { clip-path: polygon(50% 0, calc(100% - 35px) 200px, 35px 200px); -webkit-clip-path: polygon(50% 0, calc(100% - 35px) 200px, 35px 200px); }
    .footer-cta { margin-top: 70px; }
    .block-hotel-gm-map .section-map #map { height: 433px }
    .header { position: relative; }
    .block-destination-images { margin-top: 0;}
    .block-inspiration-3 .block-inspiration-image .block-inspiration-more { right: -7px; }
    .block-content { padding: 0; }
    .block-carousel-inspiration { margin-bottom: 70px; }
    .block-carousel-inspiration { padding: 0; }
    .link-more { margin-top: 0; padding-top: 0;}
    .block-carousel-inspiration .owl-theme .owl-nav { bottom: 0; }
    .breadcrumbs-begin { padding-top: 0 !important; }
    .block-inspiration-head-category { margin-top: 30px; }
    .breadcrumbs-only { margin: 0 0 70px !important; }
    .block-map-description { margin-bottom: 20px; }
}

@include media-breakpoint-down(md) {
    .block-carousel-background-item-title-line1 { font-size: 14px; }
    .block-carousel-background-item-title-line2, .top, .bot { font-size: 30px; }
    .top:before { bottom: -12px; }
    .bot:before { top: -29px; }
    .block-carousel-background-item-title-subtitle { font-size: 12px; bottom: -50px;}
    .block-destination-images ul { justify-content: center !important;}
    .footer-cta-link.first { margin: 0 0 10px; }
    .block-title { margin: 0 0 20px; padding: 38px 0; text-align: center !important; transform: none !important; }
    .block-title-number { font-size: 150px; }
    .block-title-line-1 { font-size: 12px; }
    .block-title-line-2 { font-size: 20px; }
    .block-carousel-background .search-destination { padding: 20px; }
    .block-inspiration .container:before { display: none; }
    .block-philosophy-item:before { display: none; }
    .block-philosophy-more { margin: 30px 0 0; text-align: center; }
    .block-gallery-item-content-line1 { font-size: 10px; }
    .block-gallery-item-content-line2 { font-size: 14px; }
    .breadcrumbs-only { margin: 0 0 30px !important; }
    .block-concept-description { margin: 0 0 20px;}
    .block-concept-images { padding: 0; margin: 0 0 30px; }
    .block-concept-images:before { display: none; }
    .block-selected-press-images { padding: 0;}
    .block-selected-press-images:before, .block-press .block-selected-press-category:before { display: none; }
    .block-hotel-content-description-left { position: relative; margin: 15px 0; padding: 15px 0; border: none; }
    .block-hotel-content-description-left:before { content: ''; position: absolute; background: #ccc; width: 100px; height: 1px;top: 0; left: 0; }
    .block-hotel-content-description-left:after { content: ''; position: absolute; background: #ccc; width: 100px; height: 1px;bottom: 0; left: 0; }
    .block-hotel-content-description-center { margin: 0; padding: 0; border: none; }
    .block-hotel-content-description-center:before { display: none; }
    .block-hotel-content-description-center:after { display: none; }
    .block-hotel-gm-map .section-map #map { height: 321px }
    .block-inspiration-3 .block-inspiration-image .block-inspiration-more { bottom: auto; top: 100%; }
    .block-gallery-items { margin-top: -8px; }
    .block-gallery-items.row { margin-left: -5px; margin-right: -5px; }
    .block-gallery-item { margin-top: 8px; }
    .block-gallery-items > div { padding-right: 5px; padding-left: 5px; }
    .block-map .block-title { margin-top: 0;}
    .block-philosophy-title:before { display: none; }
    .block-philosophy-description { margin-bottom: 20px; }
    .block-concept-images > div { margin-left: 5px; }
    .block-history-image { padding-left: 5px; padding-right: 0; }
    .block-selected-press-images { padding-right: 30px; }
    .block-selected-press-images-block { padding-top: 0; }
    .block-selected-press-images-block:before { display: none; }
    .block-selected-press-more { right: 0; }
    .block-press-articles-item-image:before { display: none; }
    .block-press-articles-item { text-align: center; }
    .header .wrap-btn-menu { position: absolute; top: 0; display: flex; align-items: center; height: 100%; left: 20px; padding: 4px 0; }
}

@include media-breakpoint-down(sm) {
    .block-carousel-background-image-desktop { display: none !important; }
    .block-carousel-background-image-mobile { display: block !important; height: calc(100vh - 135px); width: auto !important; }
    .block-inspiration-content-image-legend { position: relative; transform: none; left: auto; top: auto; text-align: center; width: auto; padding-bottom:30px; }
    .block-inspiration-content-image-legend:before { top: auto; bottom: 0; left: 50%; transform: translate(-50%, 0); }
    .block-carousel-background-item-title-line1 { font-size: 12px; }
    .block-carousel-background-item-title-line2, .top, .bot { font-size: 20px; }
    .block-carousel-background-item-title-subtitle { font-size: 12px; bottom: -50px;}
    .top:before { bottom: -7px; }
    .bot:before { top: -19px; }
    .block-carousel-background .search-destination { padding: 10px; display: block; text-align: center; }
    .block-carousel-background .search-destination-or { padding: 10px 0; }
    .block-carousel-background-item-title { top: 50%; transform: translate(0, -50%); }
    .breadcrumbs-begin { padding: 0;}
    .header .top-bar {  display: flex; justify-content: center; top: 3px; right: 5px; }
    .top-bar span { display: none; }
    .header .top-bar-contact svg, .header .top-bar-phone svg { margin: 0; }
    .top-bar a { display: block; padding: 5px; }
    .header .top-bar-separator { display: none; }
    .top-bar > div { display: block !important; }
    .block-destination-images { margin: 0; padding: 0; }
    .block-destination-images:before { display: none; }
    .block-carousel-experience:before { display: none; }
    .block-carousel-experience-more a { transform: none !important; position: static; }
    .block-inspiration-separate { position: relative; margin-bottom: 30px; padding: 0 0 30px !important; text-align: center !important; }
    .block-inspiration-separate:after { content: ''; position: absolute;bottom: 0; left: 50%; transform: translate(-50%, 0); width: 50px; height: 1px; background: #ccc; }
    .block-inspiration-desc { margin: 0 0 30px; }
    .block-inspiration-2 img { margin: 0 0 0 }
    .block-inspiration-more { margin: 30px 0 0 }
    .block-inspiration-3 .block-inspiration-more a { transform: none !important; position: static; }
    .block-inspiration-3 { margin: auto;}
    .block-carousel-background .search-destination-wrap { padding: 0 10px; display: block; }
    .block-carousel-background .search-destination .nice-select { width: 100%; padding: 0 35px 0 10px; }
    .block-content-bullets-line ul li { padding: 0 5px; }
    .block-carousel-inspiration:before { display: none; }
    .block-carousel-inspiration-item-wrap { height: auto; padding: 10px 10px 30px; margin: 10px 0 0; }
    .block-carousel-inspiration .owl-theme .owl-nav { position: static; margin-top: 0; }
    .block-carousel-inspiration-item { text-align: center; }
    .block-map .section-map { padding: 0; }
    .block-map .section-map:before { display: none; }
    .block-press-articles-item .more-link { position: static; }
    .block-map-description { margin: 0 0 30px;}
    .block-contact-form-wrap { padding: 30px 10px}
    .block-join-images:before { display: none; }
    .header .top-bar { display: none; }
    .header .search-btn-wrap { display: none; }
    .block-hotel-gm-map .section-map #map { height: 266px }
    .block-destination-images ul li:nth-child(4) { margin-left: 0 }
    .block-destination-images ul li { margin-top: 18px; }
    .block-destination-images ul { margin-top: -18px; }
    .block-carousel-experience { padding-top: 0; }
    .block-accomodation-item:before { display: none; }
    .block-selected-press-more { top: 130%; right: -15px; }
}

@include media-breakpoint-down(xs) {
    .search-destination-wrap { margin-top: 10px; }
    .block-carousel-background .owl-carousel .owl-item img { height: calc(100vh - 95px); object-fit: cover;}
    .header { padding: 0; }
    .header-logo { padding: 10px 0; }
    #mobile-menu .item-container ul > li ul li a { font-size: 4vw; }
    .header-logo svg { width: 130px; height: 54px;}
    .top:before { bottom: -8px; }
    .footer-partner { display: flex; }
    .nice-select { font-size: 12px; }
    .footer-partner a { display: block; margin: 5px 10px; }
    .footer-newsletter { padding: 10px;}
    .footer-newsletter .ajax-loader { position: static; }
    .block-carousel-background .search-destination .nice-select .current { line-height: 50px; }
    .block-selected-press-images { padding: 0; }
    .block-selected-press-images-block { display: block !important; text-align: center; margin-top: -20px; }
    .block-selected-press-block { margin-top: 20px; width: auto; }
    .block-selected-press-more { display: none; }
    .block-destination-images ul li:nth-child(3) { margin-left: 0 }
    .block-destination-images ul li:nth-child(4) { margin-left: 20px }
    .block-destination-images ul li:nth-child(5) { margin-left: 0 }
    .breadcrumbs { font-size: 10px; }
    .breadcrumbs .separator { padding: 0 5px; }
    .block-carousel-experience-more { position: static; padding-top: 10px; transform: none; }
    .block-carousel-experience-more svg{ transform: rotate(90deg) }
    .block-inspiration .block-inspiration-more { position: static; transform: none; }
    .block-inspiration .block-inspiration-more svg{ transform: rotate(90deg) }
    .block-inspiration-3 { padding-right: 0; }
    .block-philosophy .link-more { padding-top: 20px; }
    .block-map .section-map.section-map-left { padding: 0; }
    .block-press-articles-item-image { padding-right: 0; }
}